import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'partidos',
    loadChildren: () => import('./partidos/partidos.module').then( m => m.PartidosPageModule)
  },
  {
    path: 'posiciones',
    loadChildren: () => import('./posiciones/posiciones.module').then( m => m.PosicionesPageModule)
  },
  {
    path: 'equipos',
    loadChildren: () => import('./equipos/equipos.module').then( m => m.EquiposPageModule)
  },
  {
    path: 'detalle-posiciones',
    loadChildren: () => import('./detalle-posiciones/detalle-posiciones.module').then( m => m.DetallePosicionesPageModule)
  },
  {
    path: 'rankings',
    loadChildren: () => import('./rankings/rankings.module').then( m => m.RankingsModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
